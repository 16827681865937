// This file is generated automatically by scripts/generate-routes.ts
// Do not edit this file directly.
// To regenerate this file, run:
//   yarn generate-routes
//
import { components, hooks, utils } from "~/utils/router.generouted";

// all paths get defined here...
export type Path =
  | "/"
  | "/confirm-email/:uid/:token"
  | "/drive"
  | "/drive/:driveId"
  | "/drive/:driveId/analytics"
  | "/drive/:driveId/destination/:destinationId/link/:trackingLinkId/edit"
  | "/drive/:driveId/destination/:destinationId/link/new"
  | "/drive/:driveId/destination/link/new"
  | "/drive/:driveId/destinations"
  | "/drive/:driveId/destinations/:destinationId"
  | "/drive/:driveId/export"
  | "/drive/:driveId/folder/:folderId"
  | "/drive/:driveId/label/:labelId"
  | "/drive/:driveId/new/destination"
  | "/drive/:driveId/settings"
  | "/drive/:driveId/settings/general"
  | "/login"
  | "/logout"
  | "/new-account-form/:uid/:token"
  | "/reset-password"
  | "/reset-password-verify/:uid/:token"
  | "/uploader/drive/:driveId/destination/:destIdToUpdate/blank"
  | "/uploader/drive/:driveId/destination/:destIdToUpdate/files"
  | "/uploader/drive/:driveId/destination/:destIdToUpdate/urls/:urlToCreate"
  | "/uploader/drive/:driveId/destination/blank"
  | "/uploader/drive/:driveId/destination/files"
  | "/uploader/drive/:driveId/destination/urls/:urlToCreate"
  | "/uploader/drive/:driveId/results"
  | "/uploader/drive/:driveId/uploading";

// all params for any given path get defined here...
export type Params = {
  "/confirm-email/:uid/:token": { uid: string; token: string };
  "/drive/:driveId": { driveId: string };
  "/drive/:driveId/analytics": { driveId: string };
  "/drive/:driveId/destination/:destinationId/link/:trackingLinkId/edit": {
    driveId: string;
    destinationId: string;
    trackingLinkId: string;
  };
  "/drive/:driveId/destination/:destinationId/link/new": {
    driveId: string;
    destinationId: string;
  };
  "/drive/:driveId/destination/link/new": { driveId: string };
  "/drive/:driveId/destinations": { driveId: string };
  "/drive/:driveId/destinations/:destinationId": {
    driveId: string;
    destinationId: string;
  };
  "/drive/:driveId/export": { driveId: string };
  "/drive/:driveId/folder/:folderId": { driveId: string; folderId: string };
  "/drive/:driveId/label/:labelId": { driveId: string; labelId: string };
  "/drive/:driveId/new/destination": { driveId: string };
  "/drive/:driveId/settings": { driveId: string };
  "/drive/:driveId/settings/general": { driveId: string };
  "/new-account-form/:uid/:token": { uid: string; token: string };
  "/reset-password-verify/:uid/:token": { uid: string; token: string };
  "/uploader/drive/:driveId/destination/:destIdToUpdate/blank": {
    driveId: string;
    destIdToUpdate: string;
  };
  "/uploader/drive/:driveId/destination/:destIdToUpdate/files": {
    driveId: string;
    destIdToUpdate: string;
  };
  "/uploader/drive/:driveId/destination/:destIdToUpdate/urls/:urlToCreate": {
    driveId: string;
    destIdToUpdate: string;
    urlToCreate: string;
  };
  "/uploader/drive/:driveId/destination/blank": { driveId: string };
  "/uploader/drive/:driveId/destination/files": { driveId: string };
  "/uploader/drive/:driveId/destination/urls/:urlToCreate": {
    driveId: string;
    urlToCreate: string;
  };
  "/uploader/drive/:driveId/results": { driveId: string };
  "/uploader/drive/:driveId/uploading": { driveId: string };
};

// Utility type to extract the leaf nodes of Params
type LeafNodes<T> = T[keyof T];

// Merge all leaf nodes into one big object type
type MergeLeafNodes<T> = UnionToIntersection<LeafNodes<T>>;

// Utility type to convert a union type to an intersection type
type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (
  k: infer I,
) => void
  ? I
  : never;

// Combined type of all the leaf nodes
export type AllParams = MergeLeafNodes<Params>;

export const { Link, NavLink } = components<Path, Params>();
export const { useNavigate, useParams } = hooks<Path, Params>();
export const { redirect, generatePath } = utils<Path, Params>();
